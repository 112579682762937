<template>
  <el-dialog
    class="grade-dialog"
    :title="`${preTitle}院系`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="540px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="院系名称" prop="collegeName">
        <el-input v-model.trim="formData.collegeName" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="save('院系',addCollege)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addCollege, getCollegeById } from '@/api/college'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'addCollege',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        collegeName: '', // 院系
        schMajors: [], // 院系专业
        remarks: ''
      },
      rules: {
        collegeName: [{
          required: true,
          message: '请输入院系名称',
          trigger: 'blur'
        }, this.$store.state.commonValidate.limit20WordsObj]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getCollegeById)
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    addCollege // 保存api
  }
}
</script>
