<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        :query-info.sync="queryInfo"
        :tool-list="['keyword']"
        :show-clear-btn="false"
        @on-search="renderTable(1)"
        style="display: inline-block;"
      />
      <div class="header-button fr">
        <upload-excel :permit-key="['basic:college:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importCollege"
                      @on-download="$http.exportExcelHttp.exportCollegeTemplate()"
                      @on-success="renderTable(1)" />
        <el-button v-permission="['basic:college:edit']" type="primary" size="small" @click="showDialog()">添加院系
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="collegeName" label="院系名称" width="200" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-permission="['basic:college:edit']"
                     type="primary"
                     size="mini"
                     @click="showDialog( row.id)"
          >编辑
          </el-button>
          <el-button v-permission="['basic:college:del']"
                     size="mini"
                     type="danger"
                     @click="del(deleteCollege, row.id,`院系-${row.collegeName}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-college ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getCollegeList, deleteCollege } from '@/api/college'
import tableView from '@/vue/mixins/table-view'
import AddCollege from '@/views/pages/baseInfo/college/dialog/addCollege.vue'

export default {
  name: 'college',
  components: {
    AddCollege
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: null
      }
    }
  },
  created () {
    this.renderTable(1)
  },
  methods: {
    deleteCollege, // 删除api
    // 获取院系列表
    async renderTable (pageNum) {
      await this.getTableData(getCollegeList, pageNum)
    }
  }
}
</script>
